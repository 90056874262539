import React, { Suspense } from 'react'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import GlobalProviders from './components/GlobalProviders'
import { AdminAuthProvider } from './context/AdminAuth'
import { CLIENT_OTP_TOKEN_STORAGE_KEY } from './libraries/Axios'

import './App.css'
import './pages/lead-intake/style.css'
import './pages/qualify-website/style.css'
import './scss/index.scss'
import CheckoutSETC from './pages/checkout-setc'

const IncognitoError = React.lazy(() => import('./pages/error-pages/IncognitoError'))
const Admin = React.lazy(() => import('./pages/admin'))
const LeadIntake = React.lazy(() => import('./pages/lead-intake'))
const Referrer = React.lazy(() => import('./pages/referrer'))
const FormEngineDemo = React.lazy(() => import('./pages/form-engine-demo'))
const LongQuestionnaire = React.lazy(() => import('./pages/long-questionnaire'))
const OTPVerifierPage = React.lazy(() => import('./pages/otp-verifier'))
const SubAgentSignup = React.lazy(() => import('./pages/subagent-signup'))
const ClientPaymentOptions = React.lazy(() => import('./pages/admin/payment-options'))
const WelcomeBack = React.lazy(() => import('./pages/welcome-back'))
const QualifyWebsite = React.lazy(() => import('./pages/qualify-website'))
const FinchPayroll = React.lazy(() => import('./pages/finch-payroll'))
const FinchWelcome = React.lazy(() => import('./pages/finch-welcome'))
const InfluencerRegistration = React.lazy(() => import('./pages/influencer-registration'))
const ExpressRpsApplication = React.lazy(() => import('./pages/express-rps-application'))
const ClientAmbassadorApplication = React.lazy(() => import('./pages/client-ambassador-application'))
const InputEmail = React.lazy(() => import('./pages/admin/reset-password/input-email'))
const PasswordForm = React.lazy(() => import('./pages/admin/reset-password/password-form'))
const LeadIntakeSETC = React.lazy(() => import('./pages/lead-intake-setc'))
const LongQuestionnaireSETC = React.lazy(() => import('./pages/long-questionnaire-setc'))

const AuthenticatedRoute = ({ children }: { children: JSX.Element }) => {
  const [searchParams] = useSearchParams()
  window.localStorage.setItem('redirect', window.location.pathname)

  if (searchParams.get('token')) {
    window.localStorage.setItem(CLIENT_OTP_TOKEN_STORAGE_KEY, searchParams.get('token') as string)
    return children
  }

  if (!window.localStorage.getItem(CLIENT_OTP_TOKEN_STORAGE_KEY)) {
    const finchCode = searchParams.get('code')
    const pinUrl = finchCode ? `/one-time-pin?code=${finchCode}` : '/one-time-pin'

    return <Navigate to={pinUrl} replace />
  }

  return children
}

const SuspenseFallbackComponent = () => {
  return <div>
    <div className="lds-container">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
}

function App() {
  return (
    <GlobalProviders>
      <div className="App">
        <Suspense fallback={<SuspenseFallbackComponent />}>
          <Routes>
            <Route
              path="/"
              element={
                <AuthenticatedRoute>
                  <LongQuestionnaire />
                </AuthenticatedRoute>
              }
            />
            <Route path="/lead-intake" element={<LeadIntake />} />
            <Route path="/lead-intake-setc" element={<LeadIntakeSETC />} />
            <Route path="/finch-welcome"
              element={
                <AuthenticatedRoute>
                  <FinchWelcome />
                </AuthenticatedRoute>
              }
            />
            <Route path="/payroll-welcome"
              element={
                <AuthenticatedRoute>
                  <FinchWelcome />
                </AuthenticatedRoute>
              }
            />
            <Route path="/finch-payroll"
              element={
                <AuthenticatedRoute>
                  <FinchPayroll />
                </AuthenticatedRoute>
              }
            />
            <Route path="/welcome-back" element={<WelcomeBack />} />
            <Route path="/referrer-application" element={<Referrer />} />
            <Route path="/express-rps-application" element={<ExpressRpsApplication />} />
            <Route path="/client-ambassador-application" element={<ClientAmbassadorApplication />} />
            <Route path="/signup/:id" element={<SubAgentSignup />} />
            <Route path="/form-engine-demo/*" element={<FormEngineDemo />} />
            <Route path="/one-time-pin" element={<OTPVerifierPage />} />
            <Route path="/qualify/:id" element={<QualifyWebsite />} />
            <Route path="/admin/reset-password" element={<InputEmail />} />
            <Route path="/influencer-registration" element={<InfluencerRegistration />} />
            <Route
              path="/admin/reset-password/:resetToken"
              element={<PasswordForm />}
            />
            <Route
              path="/long-questionnaire"
              element={
                <AuthenticatedRoute>
                  <LongQuestionnaire />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/long-questionnaire-setc"
              element={
                <AuthenticatedRoute>
                  <LongQuestionnaireSETC />
                </AuthenticatedRoute>
              }
            />
            <Route 
              path="/verify/checkout-setc"
              element={
                <AuthenticatedRoute>
                  <CheckoutSETC />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/payment-options"
              element={
                <AuthenticatedRoute>
                  <ClientPaymentOptions />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/admin/*"
              element={
                <AdminAuthProvider>
                  <Admin />
                </AdminAuthProvider>
              }
            />
            <Route
              path="/error/incognito"
              element={
                <IncognitoError />
              }
            />
          </Routes>
        </Suspense>
      </div>
      <ToastContainer />
    </GlobalProviders>
  )
}

export default App
